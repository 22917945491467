<template>
  <v-row v-if="organizations !== null">
    <template v-for="organization in organizations">
      <v-col
        :key="organization.uuid"
        cols="12"
        lg="4"
        md="4"
        sm="6"
        xl="4"
      >
        <OrganizationCard :organization="organization" />
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import OrganizationCard from "./OrganizationCard"

  export default {
    name: 'OrganizationGrid',
    components: {
      OrganizationCard
    },
    props: {
      organizations: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
    }),
    mounted() {
      this.$store.dispatch('getYessProfiles')
    }
  }
</script>
